import React from 'react';
import config from '../config';

export default React.createClass({

    getInitialState() {
        return {
            width:  Math.round(this.props.element.width * 100),
            height: Math.round(this.props.element.height * 100),
            z:      Math.round(this.props.element.z * 100)
        };
    },

    render() {

        this.state = {
            width:  Math.round(this.props.element.width * 100),
            height: Math.round(this.props.element.height * 100),
            z:      Math.round(this.props.element.z * 100)
        };

        if (this.props.element.type === 'ridge') {
            return (
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">Daknok</h3>
                    </div>
                    <div className="panel-body">
                        <p>
                            <label>Nokhoogte: <span className="value">{this.state.height} cm</span></label>
                            <input type="range" min="0" max="500" step="5" value={this.state.height} onChange={this._onChange.bind(this, 'height')} />
                        </p>
                        <p>
                            <button className="btn btn-warning btn-sm" onClick={this._clickCancel}>
                                <span className="glyphicon glyphicon-remove" />Annuleren
                            </button>
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">{this.props.element.type === 'window' ? 'Raam' : 'Deur'}</h3>
                </div>
                <div className="panel-body">
                    <p>
                        <label>Breedte: <span className="value">{this.state.width} cm</span></label>
                        <input type="range" min="50" max="400" step="5" value={this.state.width} onChange={this._onChange.bind(this, 'width')} />
                    </p>
                    <p>
                        <label>Hoogte: <span className="value">{this.state.height} cm</span></label>
                        <input type="range" min="50" max={config.wall.height * 100 - 20} step="5" value={this.state.height} onChange={this._onChange.bind(this, 'height')} />
                    </p>
                    {this.props.element.type === 'window' ? <p>
                        <label>Verticale positie: <span className="value">{this.state.z} cm</span></label>
                        <input type="range" min="10" max={config.wall.height * 100 - 50} step="5" value={this.state.z} onChange={this._onChange.bind(this, 'z')} />
                    </p> : null}
                    <p>
                        <button className="btn btn-danger btn-sm" onClick={this._clickRemove}>
                            <span className="glyphicon glyphicon-trash" />Wis
                        </button>
                        <button className="btn btn-warning btn-sm" onClick={this._clickCancel}>
                            <span className="glyphicon glyphicon-remove" />Annuleren
                        </button>
                    </p>
                </div>
            </div>
        );
    },

    _onChange(property, e) {
        this.state[property] = e.target.value;
        this.setState(this.state);
        this.props.world.emit('modify', this.props.element, this.state);
    },

    _clickCancel() {
        this.props.world.emit('cancel');
    },

    _clickRemove() {
        this.props.world.emit('delete', this.props.element);
    }
});
