import EventEmitter from 'events';
import THREE from 'three';
import '../lib/OrbitControls';
import {createGrid, createLights} from './creators';

import Controller from './controller';
import Model from './model';

export default class World extends EventEmitter {

    constructor(color, width, height) {
        super();
        this.width = width;
        this.height = height;
        this.floor = 0;
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setClearColor(color, 1);
        this.scene.fog = new THREE.Fog(color, 0, 400);
        this.render_callback = this.render.bind(this);

        this.initOrtho();
        this.initPerspective();
        this.setDimesions(width, height);
        this.build();

        this.camera = this.ortho;
        this.view_mode = false;
        this.perspective_controls.enabled = false;

        this.model = new Model(this);
        this.controller = new Controller(this, this.model);
    }

    get element() {
        return this.renderer.domElement;
    }

    get ortho_width() {
        return (this.ortho.right - this.ortho.left) / this.ortho.zoom;
    }

    get ortho_height() {
        return (this.ortho.bottom - this.ortho.top) / this.ortho.zoom;
    }

    listen() {

        this.on('edit', () => {
            this.view_mode = false;
            this.camera = this.ortho;
            this.ortho_controls.enabled = true;
            this.perspective_controls.enabled = false;
        });

        this.on('view', () => {
            this.view_mode = true;
            this.camera = this.perspective;
            this.perspective_controls.enabled = true;
            this.ortho_controls.enabled = false;
        });

        this.on('floor', (floor) => {
            this.floor = parseInt(floor, 10);
        });

        this.container.addEventListener('mousedown', (e) => {
            if (e.button === 0) {
                this.emit('down');
            }
        });

        this.container.addEventListener('mouseup', (e) => {
            if (e.button === 0) {
                this.emit('up');
            }
        });

        this.container.addEventListener('mousemove', (e) => {
            this.emit('move', e.pageX, e.pageY);
        });

        this.container.addEventListener('mousewheel', (e) => {
            this.emit('move', e.pageX, e.pageY);
        });

        document.addEventListener('keydown', (e) => {
            if (e.which === 27) {
                this.emit('cancel');
            }
        });

        this.emit('update_measures', this.model.getMeasures());

        this.controller.listen();
    }

    setDimesions(width, height) {
        this.width = width;
        this.height = height;
        this.perspective.aspect = width / height;
        this.perspective.updateProjectionMatrix();
        this.ortho.left = width / - 80;
        this.ortho.right = width / 80;
        this.ortho.top = height / 80;
        this.ortho.bottom = height / - 80;
        this.ortho.updateProjectionMatrix();
        this.renderer.setSize(width, height);
    }

    initOrtho() {
        this.ortho = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 500);
        this.ortho.up.set(0, 0, 1);
        this.ortho.lookAt(new THREE.Vector3(0, 0, 0));
        this.ortho.position.set(0, 0, 80);
        this.ortho_controls = new THREE.OrbitControls(this.ortho, this.element);
        this.ortho_controls.enableRotate = false;
        this.ortho.up.set(0, 0, 1);
    }

    initPerspective() {
        this.perspective = new THREE.PerspectiveCamera(45, 1, 0.1, 500);
        this.perspective.up.set(0, 0, 1);
        this.perspective.lookAt(new THREE.Vector3(0, 0, 0));
        this.perspective.position.set(0, 0, Math.sqrt(this.width * this.height) / 45);
        this.perspective.updateProjectionMatrix();
        this.perspective_controls = new THREE.OrbitControls(this.perspective, this.element);
        this.perspective_controls.maxPolarAngle = Math.PI / 2 - 0.05;
    }

    project(x, y) {
        return new THREE.Vector2(
            Math.round((this.ortho.position.x + this.ortho_width * (x / this.width - 0.5)) * 5) / 5,
            Math.round((this.ortho.position.y + this.ortho_height * (y / this.height - 0.5)) * 5) / 5
        );
    }

    inverse(point) {
        return new THREE.Vector2(
            ((point.x - this.ortho.position.x) / this.ortho_width + 0.5) * this.width,
            ((point.y - this.ortho.position.y) / this.ortho_height + 0.5) * this.height
        );
    }


    build() {
        this.scene.add(createGrid(500, 0xcccccc));
        this.scene.add(createLights());
    }

    render() {
        this.emit('render');
        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(this.render_callback);
    }
}
