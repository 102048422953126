import THREE from 'three';
import config from './config';
import Wall from './wall';
import Roof from './roof';
import Lot from './lot';
import History from './history';
import Object3D from './object3d';
import * as util from './util';

export default class Model {

    constructor(world) {
        this.world = world;
        this.walls = [];
        this.objects = [];
        this.history = new History(this);
        this.load();
    }

    add(element) {
        this.walls.push(element);
        this.history.add(this.walls.length - 1, element.serialize(), true);
    }

    get(index) {
        return this.walls[index];
    }

    count() {
        return this.walls.length;
    }

    clearAll() {
        for (let wall = 0; wall < this.walls.length; wall += 1) {
            if (this.walls[wall]) {
                this.world.scene.remove(this.walls[wall].shape);
            }
        }
        this.walls = [];
        for (let object = 0; object < this.objects.length; object += 1) {
            if (this.objects[object]) {
                this.world.scene.remove(this.objects[object].shape);
            }
        }
        this.objects = [];
    }

    deserialize(object) {
        if (object.model_type === 'objects') {
            return new Object3D(this.world, object.type, object.position);
        }

        let item;

        if (object.roof) {
            item = new Roof(this.world);
        } else if (object.lot) {
            item = new Lot(this.world);
        } else {
            item = new Wall(this.world);
        }

        item.floor = object.floor;
        item.decoration = object.decoration;
        item.closed = object.closed;

        for (let point of object.points) {
            item.points.push(new THREE.Vector2(point.x, point.y));
        }

        for (let list of object.elements) {
            if (list) {
                for (let element of list) {
                    item.addElement(element.point, element.time, element.type, element);
                }
            }
        }

        return item;
    }

    load() {
        var data = JSON.parse(localStorage.getItem('model'));

        if (!data || !data.walls) {
            return;
        }

        for (let wall of data.walls) {
            let item = this.deserialize(wall);
            this.add(item);
            item.builder.update();
        }

        for (let object of data.objects) {
            this.addObject(object.type, object.position);
        }
    }

    save() {
        var data = {walls: [], objects: []};
        for (let wall of this.walls) {
            if (wall) {
                data.walls.push(wall.serialize());
            }
        }
        for (let object of this.objects) {
            if (object) {
                data.objects.push(object.serialize());
            }
        }

        localStorage.setItem('model', JSON.stringify(data));
    }

    getMeasures() {
        let area = 0;
        let wall_length = 0;

        let current = null;
        let next = null;

        for (let wall = 0; wall < this.walls.length; wall += 1) {
            if (this.walls[wall] && !this.walls[wall].roof && !this.walls[wall].lot) {
                for (let point = 0; point < this.walls[wall].points.length - 1; point += 1) {
                    current = this.walls[wall].points[point];
                    next = this.walls[wall].points[point + 1];
                    wall_length += current.distanceTo(next);
                    area += (current.x + next.x) * (current.y - next.y);
                }
            }
        }

        return {
            wall_length: wall_length,
            area: area / 2
        };
    }

    snap(position) {
        for (let wall = 0; wall < this.walls.length; wall += 1) {
            if (this.walls[wall] && this.walls[wall].floor === this.world.floor - 1) {
                for (let point = 0; point < this.walls[wall].points.length; point += 1) {
                    if (this.walls[wall].points[point].distanceTo(position) < config.delta) {
                        position.set(
                            this.walls[wall].points[point].x,
                            this.walls[wall].points[point].y
                        );
                    }
                }
            }
        }
    }

    updateWalls() {
        for (let wall = 0; wall < this.walls.length; wall += 1) {
            if (this.walls[wall]) {
                this.walls[wall].builder.update();
            }
        }
    }

    getWallCorner(position) {
        for (let wall = 0; wall < this.walls.length; wall += 1) {
            if (this.walls[wall] && this.walls[wall].floor === this.world.floor) {
                for (let point = 0; point < this.walls[wall].points.length; point += 1) {
                    if (this.walls[wall].points[point].distanceTo(position) < config.delta) {
                        return {wall: this.walls[wall], point};
                    }
                }
            }
        }

        return null;
    }

    getElement(position) {
        for (let wall = 0; wall < this.walls.length; wall += 1) {
            if (this.walls[wall] && this.walls[wall].floor === this.world.floor) {
                for (let point = 0; point < this.walls[wall].points.length; point += 1) {
                    if (this.walls[wall].elements[point]) {
                        for (let i = 0; i < this.walls[wall].elements[point].length; i += 1) {
                            let element = this.walls[wall].elements[point][i];
                            if (position.distanceTo(element.position) < config.delta) {
                                return element;
                            }
                        }
                    }
                }
            }
        }

        return null;
    }

    getElementPosition(type, position) {
        var min = 10000;
        let current = null;
        let ridge = type === 'ridge';

        for (let wall = 0; wall < this.walls.length; wall += 1) {
            if (this.walls[wall]) {
                let roof = this.walls[wall].roof;
                if ((ridge && roof) || (!ridge && !roof) && !this.walls[wall].lot && this.walls[wall].floor === this.world.floor) {
                    for (let point = 0; point < this.walls[wall].last; point += 1) {
                        let a = this.walls[wall].points[point];
                        let b = this.walls[wall].points[point + 1];
                        let projection = util.project(a, b, position, config[type].margin);

                        if (projection) {
                            let [pos, time] = projection;
                            let distance = position.distanceTo(pos);
                            if (distance < min) {
                                min = distance;
                                current = {wall: this.walls[wall], point, type, position: pos, time};
                            }
                        }
                    }
                }
            }
        }

        return current;
    }

    setPoint(corner, position) {
        this.undo = corner.wall.serialize();
        corner.wall.setPoint(corner.point, position);
        let index = this.walls.indexOf(corner.wall);
        this.history.add(index, corner.wall.serialize(), false, this.undo);
    }

    removeElement(element) {
        this.undo = element.wall.serialize();
        element.wall.removeElement(element.point, element.index);
    }

    addElement(type, position, state, skip) {
        let current = this.getElementPosition(type, position);
        if (!current.wall) {
            return null;
        }
        this.undo = this.undo ? this.undo : current.wall.serialize();
        let element = current.wall.addElement(current.point, current.time, type, state, skip);
        this.history.add(this.walls.indexOf(current.wall), current.wall.serialize(), false, this.undo);
        delete this.undo;
        return element;
    }

    addObject(type, position) {
        let object = new Object3D(this.world, type, position);
        this.objects.push(object);
        this.history.add(this.objects.indexOf(object), object.serialize(), true);
    }

    getObject(position) {
        for (let i = 0; i < this.objects.length; i += 1) {
            if (this.objects[i] && this.objects[i].position.distanceTo(position) < 1) {
                return this.objects[i];
            }
        }
        return null;
    }

    setObjectPosition(object, position) {
        let undo = object.serialize();
        let index = this.objects.indexOf(object);
        object.setPosition(position);
        this.history.add(index, object.serialize(), false, undo);
    }
}
