import THREE from 'three';
import Wall from './wall';
import Roof from './roof';
import Lot from './lot';
import {createCursor} from './creators';
import * as util from './util';
import config from './config';

export default class Controller {

    constructor(world, model) {
        this.world = world;
        this.model = model;

        this.position = new THREE.Vector2();
        this.last_position = new THREE.Vector2();

        this.enabled = true;
        this.tool = null;
    }

    listen() {

        this.world.on('clear', () => {
            this.model.clearAll();
        });

        this.world.on('save', () => {
            this.model.save();
        });

        this.world.on('edit', () => {
            this.enabled = true;
            this.model.updateWalls();
        });

        this.world.on('select', (tool, inner) => {
            // set the tool
            this.tool = tool;
            this.inner = false;
            // exception for inner wall
            if (this.tool === 'inner-wall') {
                this.tool = 'wall';
                this.inner = true;
            }
        });

        this.world.on('modify', (element, state) => {
            element.wall.modifyElement(element, state);
        });

        this.world.on('floor', () => {
            this.model.updateWalls();
        });

        this.world.on('cancel', () => {
            // reset state
            this.tool = null;
            this._setState();
            this.world.emit('measure.hide');
            if (this.current) {
                this.current.remove();
                this.current = null;
            }
        });

        this.world.on('view', () => {
            this.enabled = false;
            this.model.updateWalls();
        });

        this.world.on('delete', (element) => {
            this.model.removeElement(element);
        });

        this.world.on('move', (x, y) => {
            // update the mouse position
            if (this.enabled) {
                this.position.copy(this.world.project(x, y));
            }
        });

        this.world.on('down', () => {
            // drag a wall point
            if (this.enabled && !this.tool) {
                this.drag_corner = this.model.getWallCorner(this.position);
                this.drag_element = this.model.getElement(this.position);
                this.drag_object = this.model.getObject(this.position);

                if (this.drag_element) {
                    this.world.emit('element', this.drag_element);
                }
            }
        });

        this.world.on('up', () => {
            if (this.enabled) {
                this._onUp();
            }
        });

        this.world.on('render', () => {
            if (this.enabled && !this.position.equals(this.last_position)) {
                this.last_position = this.position.clone();
                this._onRender();
            }
        });
    }

    _setState(state) {
        this.world.container.className = state ? state : '';
    }

    _onUp() {
        if (this.drag_corner) {
            // dragging a wall point
            this.drag_corner = null;
            this.world.emit('cancel');
            this.world.emit('update_measures', this.model.getMeasures());
        } else if (this.drag_element) {
            this.world.emit('element', this.drag_element);
            this.drag_element = null;
        } else if (this.drag_object) {
            this.world.emit('cancel');
            this.drag_object = null;
        } else if (this.tool === 'door' || this.tool === 'window') {
            // placing a door or window
            this.model.addElement(this.tool, this.position);
            this.world.emit('cancel');
        } else if (this.tool === 'roof_tiles' || this.tool === 'roof_stone' || this.tool === 'roof_tarch') {
            // decorate roof
            this.hover_wall = this.model.getElementPosition('ridge', this.position);
            if (this.hover_wall) {
                this.hover_wall.wall.decorateWall(0, this.tool);
            }
            this.world.emit('cancel');
        } else if (this.tool === 'car' || this.tool === 'tree' || this.tool === 'bush' || this.tool === 'bench') {
            this.model.addObject(this.tool, this.position);
            this.world.emit('cancel');
        } else if (this.tool === 'stone' || this.tool === 'wood' || this.tool === 'white' && this.hover_wall) {
            // decorate wall
            this.hover_wall.wall.decorateWall(this.hover_wall.point, this.tool);
            this.world.emit('cancel');
        } else if (this.tool === 'wall' && !this.current) {
            // creating a new wall
            this.model.snap(this.position);
            this.current = new Wall(this.world, this.position);
        } else if (this.tool === 'lot' && !this.current) {
            // creating a new roof
            this.current = new Lot(this.world, this.position);
        } else if (this.tool === 'roof' && !this.current) {
            // creating a new roof
            this.model.snap(this.position);
            this.current = new Roof(this.world, this.position);
        } else if ((this.tool === 'wall' || this.tool === 'roof' || this.tool === 'lot') && this.current) {
            // adding a point or finishing a wall/roof
            this.model.snap(this.position);
            this.current.add(this.position);
            if (this.inner || this.current.closed) {
                if (this.inner) {
                    this.current.finish();
                }
                this.model.add(this.current);
                this.current = null;
                this.world.emit('cancel');
            }
            this.world.emit('update_measures', this.model.getMeasures());
        } else {
            this.world.emit('cancel');
        }
    }

    _onRender() {
        // drag a wall point
        if (this.drag_corner) {
            this._onDragCorner();
        } else if (this.drag_element) {
            this._onDragElement();
        } else if (this.drag_object) {
            this.model.setObjectPosition(this.drag_object, this.position);
        } else if (this.tool == 'window' || this.tool == 'door') {
            this._onPlaceElement();
        } else if (this.tool === 'car' || this.tool === 'tree' || this.tool === 'bush' || this.tool === 'bench') {
            this._setState('add');
        } else if (this.tool === 'stone' || this.tool === 'wood' || this.tool === 'white') {
            this._onDecorateWall();
            this._setState('add');
        } else if (this.tool === 'roof_tiles' || this.tool === 'roof_stone' || this.tool === 'roof_tarch') {
            this._setState('add');
        } else if (this.tool === 'wall' || this.tool === 'roof' || this.tool === 'lot') {
            if (!this.current) {
                this._setState('add');
            } else {
                this._onPlaceWall();
            }
        } else {
            this._onHover();
        }
    }

    _onDragCorner() {
        this.model.setPoint(this.drag_corner, this.position);
        this.world.emit('measure', this.drag_corner.wall.getMeasurePoints(this.drag_corner.point));
        this._setState('moving');
    }

    _onDragElement() {
        let current = this.model.getElementPosition(this.drag_element.type, this.position);
        if (current) {
            if (current.wall.isPlaceable(current.point, current.time, this.drag_element.type, this.drag_element.index, this.drag_element)) {
                this.model.removeElement(this.drag_element);
                this.drag_element = this.model.addElement(this.drag_element.type, this.position, this.drag_element, this.drag_element.index);

                if (this.drag_element) {
                    this.world.emit('measure', [
                        this.drag_element.wall.points[this.drag_element.point],
                        this.drag_element.position,
                        this.drag_element.wall.points[this.drag_element.point + 1]
                    ]);
                }
                this._setState('move');
            } else {
                this._setState('deny');
            }
        }
    }

    _onPlaceElement() {
        let current = this.model.getElementPosition(this.tool, this.position);
        if (current) {
            if (current.wall.isPlaceable(current.point, current.time, this.tool)) {
                this._setState('add');
            } else {
                this._setState('deny');
            }
            this.world.emit('measure', [
                current.wall.points[current.point],
                current.position,
                current.wall.points[current.point + 1]
            ]);
        }
    }

    _onPlaceWall() {
        this.model.snap(this.position);
        this.current.move(this.position);
        this.world.emit('measure', this.current.getMeasurePoints(this.current.last));
        if (this.current.points[0].distanceTo(this.position) < config.delta) {
            if (this.current.points.length > 3) {
                this._setState('click');
            } else {
                this._setState('deny');
            }
        } else {
            this._setState('add');
        }
    }

    _onDecorateWall() {
        this.hover_wall = this.model.getElementPosition(this.tool, this.position);
        if (this.hover_wall) {
            this.world.emit('measure', [
                this.hover_wall.wall.points[this.hover_wall.point],
                this.hover_wall.wall.points[this.hover_wall.point + 1]
            ]);
        }
    }

    _onHover() {
        let object = this.model.getObject(this.position);
        if (object) {
            this._setState('move');
        } else {
            let corner = this.model.getWallCorner(this.position);
            if (corner) {
                this.world.emit('measure', corner.wall.getMeasurePoints(corner.point));
                this._setState('move');
            } else {
                let element = this.model.getElement(this.position);
                if (element) {
                    this.world.emit('measure', [
                        element.wall.points[element.point],
                        element.position,
                        element.wall.points[element.point + 1]
                    ]);
                    this._setState('move');
                } else {
                    this._setState();
                    this.world.emit('measure.hide');
                }
            }
        }
    }
}
