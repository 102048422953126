import THREE from 'three';

var cos = Math.cos;
var sin = Math.sin;
var sign = Math.sign;
var atan2 = Math.atan2;

export function angle(a, b) {
    return atan2(b.y - a.y, b.x - a.x);
}

export function intersect(a1, a2, b1, b2) {
    let dn = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);

    if (dn === 0) {
        return 0;
    }

    let a = a1.y - b1.y;
    let b = a1.x - b1.x;
    let c = ((b2.x - b1.x) * a - (b2.y - b1.y) * b) / dn;
    let d = ((a2.x - a1.x) * a - (a2.y - a1.y) * b) / dn;

    return new THREE.Vector2(
        a1.x + c * (a2.x - a1.x),
        a1.y + c * (a2.y - a1.y)
    );
}

export function project_time(a, b, p) {
    return (
        ((p.x - a.x) * (b.x - a.x) + (p.y - a.y) * (b.y - a.y)) /
        ((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y))
    );
}

export function project(a, b, p, margin) {
    let d = a.distanceTo(b);

    if (d < margin * 2) {
        return false;
    }

    let t = project_time(a, b, p);

    if (t < margin / d) {
        return [a.clone().lerp(b, margin / d), margin / d];
    }

    if (t > 1 - margin / d) {
        return [b.clone().lerp(a, margin / d), 1 - margin / d];
    }

    let point = new THREE.Vector2(a.x + t * (b.x - a.x), a.y + t * (b.y - a.y));

    return [point, t];
}

export function corner_points(a, b, c, w) {
    let d = Math.sign((c.x - a.x) * (b.y - a.y) - (c.y - a.y) * (b.x - a.x));

    if (d === 0) {
        return;
    }

    let aa = atan2(c.y - a.y, c.x - a.x) - d * Math.PI / 2;
    let ba = atan2(c.y - b.y, c.x - b.x) + d * Math.PI / 2;
    let a1 = new THREE.Vector2(a.x + w * cos(aa), a.y + w * sin(aa));
    let a2 = new THREE.Vector2(c.x + w * cos(aa), c.y + w * sin(aa));
    let b1 = new THREE.Vector2(b.x + w * cos(ba), b.y + w * sin(ba));
    let b2 = new THREE.Vector2(c.x + w * cos(ba), c.y + w * sin(ba));
    let inter = intersect(a1, a2, b1, b2);

    if (!inter) {
        return;
    }

    return [a2, b2, inter];
}

export function point_in_polygon(point, polygon) {
    var x = point.x, y = point.y;
    var inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        let xi = polygon[i].x, yi = polygon[i].y;
        let xj = polygon[j].x, yj = polygon[j].y;

        if (((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)) {
            inside = !inside;
        }
    }

    return inside;
}

export function cross(a, b) {
    return (b.x - a.x) * (b.y + a.y);
}

export function is_clockwise(polygon) {
    let sum = 0;

    for (let i = 1; i < polygon.length; i += 1) {
        sum += cross(polygon[i - 1], polygon[i]);
    }

    return sum > 0;
}
