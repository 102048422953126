

export default class History {

    constructor(model) {
        this.model = model;
        this.history = [];
        this.position = 0;

        this.model.world.on('undo', () => {
            this.undo();
        });

        this.model.world.on('redo', () => {
            this.redo();
        });
    }

    undo () {
        if (!this.history[this.position]) {
            return false;
        }

        var item = this.history[this.position];
        let model = this.model[item.object.model_type];

        if (item.create) {
            this.model.world.scene.remove(model[item.index].shape);
            delete model[item.index];
        } else {
            this.model.world.scene.remove(model[item.index].shape);
            model[item.index] = this.model.deserialize(item.undo);
        }

        this.position -= 1;
        this.model.world.emit('floor', item.object.floor);
    }

    redo () {

        if (!this.history[this.position + 1]) {
            return false;
        }

        this.position += 1;

        var item = this.history[this.position];
        let model = this.model[item.object.model_type];

        if (item.create) {
            model[item.index] = this.model.deserialize(item.object);
        } else {
            this.model.world.scene.remove(model[item.index].shape);
            model[item.index] = this.model.deserialize(item.object);
        }

        this.model.world.emit('floor', item.object.floor);
    }

    add(index, object, create, undo) {
        let new_event = false;

        if (this.history[this.position] &&
                (this.history[this.position].create ||
                this.history[this.position].index !== index ||
                this.history[this.position].model_type !== object.model_type)) {
            this.position += 1;
            new_event = true;
        } else if (this.history[this.position]) {
            undo = this.history[this.position].undo;
        }

        this.history[this.position] = {
            index: index,
            undo: undo && undo.serialze ? undo.serialze() : undo,
            object: object,
            create: create,
            model_type: object.model_type
        };

        // remove future to prevent broken states
        this.history = this.history.slice(0, this.position + 1);

        return new_event;
    }
}
