import THREE from 'three';
import * as util from './util';
import config from './config';

export default class Builder {

    constructor(world, wall) {
        this.world = world;
        this.wall = wall;

        this.shape = new THREE.Object3D();

        var count = 0;
        let onLoad = () => {
            count += 1;
            if (count === Object.keys(this.materials).length) {
                this.update();
            }
        };

        this.materials = {
            white: THREE.ImageUtils.loadTexture(require('../../images/white.jpg'), THREE.UVMapping, onLoad),
            stone: THREE.ImageUtils.loadTexture(require('../../images/stone.jpg'), THREE.UVMapping, onLoad),
            wood: THREE.ImageUtils.loadTexture(require('../../images/wood.jpg'), THREE.UVMapping, onLoad),
            roof_tiles: THREE.ImageUtils.loadTexture(require('../../images/roof.jpg'), THREE.UVMapping, onLoad),
            roof_stone: THREE.ImageUtils.loadTexture(require('../../images/roof_stone.jpg'), THREE.UVMapping, onLoad),
            roof_tarch: THREE.ImageUtils.loadTexture(require('../../images/tarch.jpg'), THREE.UVMapping, onLoad),
            floor: THREE.ImageUtils.loadTexture(require('../../images/floor.jpg'), THREE.UVMapping, onLoad),
            lot: THREE.ImageUtils.loadTexture(require('../../images/grass.jpg'), THREE.UVMapping, onLoad)
        };

        this.materials.floor.wrapS = this.materials.floor.wrapT = THREE.RepeatWrapping;
        this.materials.lot.wrapS = this.materials.lot.wrapT = THREE.RepeatWrapping;
        this.materials.roof_tiles.wrapS = this.materials.roof_tiles.wrapT = THREE.RepeatWrapping;
        this.materials.roof_stone.wrapS = this.materials.roof_stone.wrapT = THREE.RepeatWrapping;
        this.materials.roof_tarch.wrapS = this.materials.roof_tarch.wrapT = THREE.RepeatWrapping;
    }

    update() {
        let old = this.shape;
        this.shape = new THREE.Object3D();

        if (this.wall.lot) {
            this._createLot();
        } else {
            for (let i = 0; i < this.wall.last; i += 1) {
                if ((i > 0 || this.wall.closed) && i < this.wall.last) {
                    this._createCorner(i);
                }
                this._createSegment(i);
            }

            if (this.wall.roof) {
                this._createRoof();
            } else if (this.wall.closed) {
                this._createFloorAndCeiling();
            }
        }

        this.shape.position.z = this.wall.floor * config.wall.height;

        if (this.wall.floor < this.world.floor && !this.world.view_mode) {
            this.makeTransparent(this.shape, 0.5);
        }

        this.world.scene.remove(old);

        if (this.wall.floor <= this.world.floor) {
            this.world.scene.add(this.shape);
        }

        this.wall.shape = this.shape;
    }

    makeTransparent(object, factor) {
        object.traverse((node) => {
            if(node.material) {
                if (node.material.type === 'MultiMaterial') {
                    for (let material of node.material.materials) {
                        material.opacity = factor;
                        material.transparent = true;
                    }
                } else {
                    node.material.opacity = factor;
                    node.material.transparent = true;
                }
            }
        });
    }

    remove() {
        this.world.scene.remove(this.shape);
    }

    _createWallTexture(width, height, color, point) {
        let decoration = this.wall.decoration[point] || 'white';
        let inside = new THREE.MeshLambertMaterial({color: color});
        let outside = new THREE.MeshLambertMaterial({color: color});

        let inside_texture = this.materials.white.clone();
        if (inside_texture.image && !this.wall.roof) {
            inside_texture.needsUpdate = true;
            inside_texture.wrapS = THREE.RepeatWrapping;
            inside_texture.wrapT = THREE.RepeatWrapping;
            inside_texture.repeat.set(width / 256 * 200, height / 256 * 200);
            inside = new THREE.MeshLambertMaterial({map: inside_texture});
        }

        let texture = this.materials[decoration].clone();
        if (texture.image && !this.wall.roof) {
            texture.needsUpdate = true;
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(width / 256 * 200, height / 256 * 200);
            outside = new THREE.MeshLambertMaterial({map: texture});

            if (!this.world.view_mode) {
                let top_texture = texture.clone();
                top_texture.needsUpdate = true;
                top_texture.repeat.set(width / 256 * 200, config.wall.width / 256 * 200);
                let top = new THREE.MeshLambertMaterial({map: top_texture});
                return new THREE.MeshFaceMaterial([inside, inside, outside, inside, top, inside])
            }
        }

        return new THREE.MeshFaceMaterial([inside, inside, outside, inside, inside, inside]);
    }

    _createCornerTexture(width, point) {
        let decoration = this.wall.decoration[point] || 'white';
        let material = new THREE.MeshLambertMaterial({color: config.wall.color});

        let texture = this.materials[decoration].clone();
        if (texture.image) {
            texture.needsUpdate = true;
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(width / 256 * 2000, config.wall.height / 256 * 75);
            material = new THREE.MeshLambertMaterial({map: texture});
        }

        return material;
    }

    _createLot() {
        if (this.wall.points.length < 3) {
            return;
        }

        let points = this.wall.points.slice(0);

        if (points[points.length - 1].equals(points[points.length - 2])) {
            return;
        }

        if (!this.wall.closed) {
            points.push(points[0].clone());
        }

        if (points > 3) {
            if (!util.is_clockwise(points)) {
                points = points.reverse();
            }
        }

        let shape = new THREE.Shape();

        for (let i = 0; i < points.length; i += 1) {
            if (i === 0) {
                shape.moveTo(points[i].x, points[i].y);
            } else {
                shape.lineTo(points[i].x, points[i].y);
            }
        }

        let floor = new THREE.Mesh(
            new THREE.ExtrudeGeometry(shape, {amount: 0.0005, bevelEnabled: false}),
            new THREE.MeshLambertMaterial({map: this.materials.lot})
        );

        floor.position.z = 0;

        this.shape.add(floor);
    }

    _createFloorAndCeiling() {
        let shape = new THREE.Shape();
        for (let i = 0; i <= this.wall.last; i += 1) {
            if (i === 0) {
                shape.moveTo(this.wall.points[i].x, this.wall.points[i].y);
            } else {
                shape.lineTo(this.wall.points[i].x, this.wall.points[i].y);
            }
        }

        let floor = new THREE.Mesh(
            new THREE.ExtrudeGeometry(shape, {amount: 0.01, bevelEnabled: false}),
            new THREE.MeshLambertMaterial({map: this.materials.floor})
        );
        floor.position.z = 0.002;
        this.shape.add(floor);

        if (this.wall.floor < this.world.floor || (this.world.view_mode && this.wall.floor <= this.world.floor)) {
            let ceiling = new THREE.Mesh(
                new THREE.ExtrudeGeometry(shape, {amount: 0.1, bevelEnabled: false}),
                new THREE.MeshLambertMaterial({color: 0x666666})
            );
            ceiling.position.z = config.wall.height - 0.11;
            this.shape.add(ceiling);
        }

        return floor;
    }

    _createSegment(i) {
        var start = this.wall.points[i];
        var end = this.wall.points[i + 1];
        var length = start.distanceTo(end);
        var pos = 0;
        var angle = util.angle(start, end);

        if (start.equals(end)) {
            return;
        }

        if (this.wall.elements[i]) {
            for (let element of this.wall.elements[i]) {
                element.position = new THREE.Vector2(
                    start.x + Math.cos(angle) * length * element.time,
                    start.y + Math.sin(angle) * length * element.time
                );
                if (!this.wall.roof) {
                    this._createWall(start, angle, pos, length * element.time - element.width / 2 - pos, i);
                    if (element.type === 'window') {
                        this._createWindow(start, angle, length, element);
                    } else {
                        this._createDoor(start, angle, length, element);
                    }
                    pos = length * element.time + element.width / 2;
                } else {
                    pos = 0;
                }
            }
            this._createWall(start, angle, pos, length - pos, i);
        } else {
            this._createWall(start, angle, 0, length, i);
        }
    }

    _createRoof() {
        let ridge = [];

        // select to randomn elements as ridge position
        for (let i = 0; i < this.wall.last; i += 1) {
            if (this.wall.elements[i]) {
                for (let element of this.wall.elements[i]) {
                    ridge.push({
                        index: i,
                        height: element.height,
                        position: element.position.clone()
                    });
                }
            }
        }

        if (ridge.length < 2) {
            return;
        }

        var g = new THREE.Geometry();

        for (let i = 0; i < ridge.length; i += 1) {
            ridge[i].left = this.wall.points[ridge[i].index];
            ridge[i].right = this.wall.points[ridge[i].index + 1];
            ridge[i].distance = ridge[i].left.distanceTo(ridge[i].right);
            ridge[i].lerp = ridge[i].position.distanceTo(ridge[i].right) / ridge[i].distance;

            g.vertices.push(new THREE.Vector3(ridge[i].left.x, ridge[i].left.y, config.gutter.height));
            g.vertices.push(new THREE.Vector3(ridge[i].position.x,ridge[i].position.y, ridge[i].height));
            g.vertices.push(new THREE.Vector3(ridge[i].right.x,ridge[i].right.y, config.gutter.height));
            g.faces.push(new THREE.Face3(g.vertices.length - 3, g.vertices.length - 2,g.vertices.length - 1));

            g.faceVertexUvs[0].push([
                new THREE.Vector2(ridge[i].distance, ridge[i].height),
                new THREE.Vector2(ridge[i].distance * ridge[i].lerp, 0),
                new THREE.Vector2(0, ridge[i].height)
            ]);
        }

        if (!this.world.view_mode) {
            let line_geometry = new THREE.Geometry();
            for (let i = 0; i < ridge.length; i += 1) {
                line_geometry.vertices.push(new THREE.Vector3(
                    ridge[i].position.x,
                    ridge[i].position.y,
                    ridge[i].height + 0.1
                ));
            }

            line_geometry.computeLineDistances();

            let line_handle = new THREE.Line(line_geometry,
                new THREE.LineDashedMaterial({
                    color: 0x000000,
                    linewidth: 2,
                    dashSize: 0.2,
                    gapSize: 0.1
                })
            );

            this.shape.add(line_handle);
        }

        // ridge positions
        let line, current, next, center, center_distance;
        // wall corner point positions
        let current_point, next_point;
        // projection of wall corners points to ridge
        let p_start, p_end;
        // point length
        let p_len = this.wall.points.length - 1;
        let p = this.wall.points;
        let texture_offset = -0.06;

        for (let i = 0; i < ridge.length; i += 1) {
            current = ridge[i];
            next = ridge[(i + 1) % ridge.length];

            line = new THREE.Line3(
                new THREE.Vector3(current.position.x, current.position.y, current.height),
                new THREE.Vector3(next.position.x, next.position.y, next.height)
            );

            center = line.center();
            center_distance = line.distance() / 2;

            for (let j = (current.index + 1) % p_len; j != (next.index + 1) % p_len; j = (j + 1) % p_len) {

                current_point = new THREE.Vector3(p[j].x, p[j].y, config.gutter.height);
                next_point = new THREE.Vector3(p[(j + 1) % p_len].x, p[(j + 1) % p_len].y, config.gutter.height);

                p_start = line.closestPointToPoint(current_point);
                p_end = line.closestPointToPoint(next_point);

                // first
                if (j === (current.index + 1) % p_len) {
                    g.vertices.push(line.center(), line.start, current_point);
                    g.faces.push(new THREE.Face3(g.vertices.length - 3, g.vertices.length - 2, g.vertices.length - 1));
                    g.faceVertexUvs[0].push([
                        new THREE.Vector2(texture_offset, 0),
                        new THREE.Vector2(texture_offset + center_distance, 0),
                        new THREE.Vector2(texture_offset + p_start.distanceTo(center), p_start.distanceTo(current_point))
                    ]);
                }
                // last
                if ((j + 1) % p_len === (next.index + 1) % p_len) {
                    g.vertices.push(center, line.end, current_point);
                    g.faces.push(new THREE.Face3(g.vertices.length - 3, g.vertices.length - 2, g.vertices.length - 1));
                    g.faceVertexUvs[0].push([
                        new THREE.Vector2(0, 0),
                        new THREE.Vector2(center_distance, 0),
                        new THREE.Vector2(p_start.distanceTo(center), p_start.distanceTo(current_point))
                    ]);
                } else {
                    g.vertices.push(center);
                    g.vertices.push(current_point);
                    g.vertices.push(next_point);
                    g.faces.push(new THREE.Face3(g.vertices.length - 3, g.vertices.length - 2, g.vertices.length - 1));

                    let start_offset = p_start.distanceTo(center);
                    let end_offset = p_end.distanceTo(center);

                    if (util.project_time(line.start, line.end, current_point) < 0.5) {
                        start_offset = -start_offset;
                    }

                    if (util.project_time(line.start, line.end, next_point) < 0.5) {
                        end_offset = -end_offset;
                    }

                    g.faceVertexUvs[0].push([
                        new THREE.Vector2(0, 0),
                        new THREE.Vector2(start_offset, p_start.distanceTo(current_point)),
                        new THREE.Vector2(end_offset, p_end.distanceTo(next_point))
                    ]);
                }
            }
        }

        g.computeFaceNormals();
        g.computeVertexNormals();

        let texture = this.materials[this.wall.decoration[0] || 'roof_tiles'];
        texture.repeat.set(0.3, 0.3);

        this.roof = new THREE.Mesh(g,
            new THREE.MeshLambertMaterial({
                map: texture,
                side: THREE.DoubleSide
            })
        );

        this.shape.add(this.roof);
    }

    _createWall(position, angle, offset, length, index) {

        if (this.wall.roof) {
            return this._createBlock({
                width: length,
                height: config.gutter.width,
                depth: config.gutter.height,
                position: position,
                angle: angle,
                offset: offset + length / 2,
                z: config.gutter.offset + config.gutter.height / 2,
                color: config.gutter.color[this.wall.decoration[0] || 'roof_tiles'],
                wall: true,
                point: index
            });
        }

        this._createBlock({
            width: length,
            height: config.wall.width,
            depth: config.wall.height,
            position: position,
            angle: angle,
            offset: offset + length / 2,
            z: config.wall.height / 2,
            color: config.wall.color,
            wall: true,
            point: index
        });
    }

    _createDoor(position, angle, length, element) {
        let offset = length * element.time;

        this._createBlock({
            width: element.width,
            height: config.wall.width,
            depth: config.wall.height - element.height,
            position: position,
            angle: angle,
            offset: offset,
            z: config.wall.height - (config.wall.height - element.height) / 2,
            color: config.wall.color,
            wall: true,
            point: element.point
        });

        if (!this.world.view_mode) {
            this._createBlock({
                width: element.width,
                height: config.wall.width + config.door.frame_width * 2,
                depth: 0.01,
                position: position,
                angle: angle,
                offset: offset,
                z: config.wall.height,
                color: config.door.marker_color
            });
        }

        this._createBlock({
            width: element.width,
            height: config.wall.width + config.door.frame_width * 2,
            depth: config.door.frame_width,
            position: position,
            angle: angle,
            offset: offset,
            z: element.height,
            color: config.door.frame_color
        });

        this._createBlock({
            width: element.width,
            height: config.wall.width + config.door.frame_width * 2,
            depth: 0.01,
            position: position,
            angle: angle,
            offset: offset,
            z: 0.01,
            color: config.door.frame_color
        });

        this._createBlock({
            width: config.door.frame_width,
            height: config.wall.width + config.door.frame_width * 2,
            depth: element.height,
            position: position,
            angle: angle,
            offset: offset - element.width / 2 + config.door.frame_width / 2,
            z: element.height / 2,
            color: config.door.frame_color
        });

        this._createBlock({
            width: config.door.frame_width,
            height: config.wall.width + config.door.frame_width * 2,
            depth: element.height,
            position: position,
            angle: angle,
            offset: offset + element.width / 2 - config.door.frame_width / 2,
            z: element.height / 2,
            color: config.door.frame_color
        });
    }

    _createWindow(position, angle, length, element) {
        let offset = length * element.time;

        this._createBlock({
            width: element.width,
            height: config.wall.width,
            depth: config.wall.height - (element.z + element.height),
            position: position,
            angle: angle,
            offset: offset,
            z: config.wall.height - (config.wall.height - (element.z + element.height)) / 2,
            color: config.wall.color,
            wall: true,
            point: element.point
        });

        this._createBlock({
            width: element.width,
            height: config.wall.width,
            depth: element.z,
            position: position,
            angle: angle,
            offset: offset,
            z: element.z / 2,
            color: config.wall.color,
            wall: true,
            point: element.point
        });

        if (!this.world.view_mode) {
            this._createBlock({
                width: element.width,
                height: config.wall.width + config.window.frame_width * 2,
                depth: 0.01,
                position: position,
                angle: angle,
                offset: offset,
                z: config.wall.height,
                color: config.window.marker_color
            });
        }

        this._createBlock({
            width: element.width,
            height: config.window.glass.thickness,
            depth: element.height,
            position: position,
            angle: angle,
            offset: offset,
            z: element.z + element.height / 2,
            color: config.window.glass.color,
            opacity: config.window.glass.opacity
        });

        this._createBlock({
            width: element.width,
            height: config.wall.width + config.window.frame_width * 2,
            depth: config.window.frame_width,
            position: position,
            angle: angle,
            offset: offset,
            z: element.z + element.height,
            color: config.window.frame_color
        });

        this._createBlock({
            width: element.width,
            height: config.wall.width + config.window.frame_width * 2,
            depth: config.window.frame_width,
            position: position,
            angle: angle,
            offset: offset,
            z: element.z,
            color: config.window.frame_color
        });

        this._createBlock({
            width: config.window.frame_width,
            height: config.wall.width + config.window.frame_width * 2,
            depth: element.height,
            position: position,
            angle: angle,
            offset: offset - element.width / 2 + config.window.frame_width / 2,
            z: element.z + element.height / 2,
            color: config.window.frame_color
        });

        this._createBlock({
            width: config.window.frame_width,
            height: config.wall.width + config.window.frame_width * 2,
            depth: element.height,
            position: position,
            angle: angle,
            offset: offset + element.width / 2 - config.window.frame_width / 2,
            z: element.z + element.height / 2,
            color: config.window.frame_color
        });
    }

    _createBlock(dim) {
        let material = new THREE.MeshLambertMaterial({
            color: dim.color,
            transparent: !!dim.opacity,
            opacity: dim.opacity ? dim.opacity : 1
        });

        if (dim.wall) {
            material = this._createWallTexture(dim.width, dim.depth, dim.color, dim.point);
        }

        let segment = new THREE.Mesh(
            new THREE.BoxGeometry(dim.width, dim.height, dim.depth),
            material
        );
        segment.position.x = dim.position.x + Math.cos(dim.angle) * dim.offset;
        segment.position.y = dim.position.y + Math.sin(dim.angle) * dim.offset;
        segment.position.z = dim.z;
        segment.rotation.z = dim.angle;
        this.shape.add(segment);
    }

    _createCorner(i) {
        let shape = new THREE.Shape();
        let width = this.wall.roof ? config.gutter.width : config.wall.width;
        let height = this.wall.roof ? config.gutter.height : config.wall.height;
        let a = this.wall.points[i === 0 ? this.wall.last - 1 : i - 1];
        let b = this.wall.points[(i + 1) % this.wall.points.length];
        let c = this.wall.points[i % this.wall.points.length];
        let corners = util.corner_points(a, b, c, 0.5 * width);
        let material;

        if (!corners) {
            return;
        }

        let [pa, pb, pc] = corners;
        if (c.distanceTo(pc) > Math.sqrt(2) * width / 2) {
            pc = pa.clone().lerp(pb, 0.5);
        }

        shape.moveTo(c.x, c.y);
        shape.lineTo(pa.x, pa.y);
        shape.lineTo(pc.x, pc.y);
        shape.lineTo(pb.x, pb.y);
        shape.lineTo(c.x, c.y);

        if (this.wall.roof) {
            material = new THREE.MeshLambertMaterial({
                color: config.gutter.color[this.wall.decoration[0] || 'roof_tiles']
            });
        } else {
            material = this._createCornerTexture(pa.distanceTo(pc), i);
        }

        let corner = new THREE.Mesh(
            new THREE.ExtrudeGeometry([shape], {
                amount: height,
                bevelEnabled: false
            }),
            material
        );

        if (this.wall.roof) {
            corner.position.z = config.gutter.offset;
        }

        this.shape.add(corner);
    }
}
