import THREE from 'three';

export function createGrid(size, color) {
    let shape = new THREE.Object3D();
    let geometry = new THREE.PlaneGeometry(size, size, size, size);
    let texture = THREE.ImageUtils.loadTexture(require('../../images/dirt.jpg'));

    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(size / 256 * 200, size / 256 * 200);

    let material1 = new THREE.MeshBasicMaterial({
        color: color,
        wireframe: true,
        transparent: true,
        opacity: 0.3
    });

    let material2 = new THREE.MeshBasicMaterial({
        map: texture
    });

    let mesh1 = new THREE.Mesh(geometry, material1);
    mesh1.position.z = 0.001;
    shape.add(mesh1);

    let mesh2 = new THREE.Mesh(geometry, material2);
    mesh2.position.z = -0.001;
    shape.add(mesh2);

    return shape;
}

export function createLights() {
    let shape = new THREE.Object3D();
    let light = null;
    let directions = [
        new THREE.Vector3(1, 0, 0),
        new THREE.Vector3(-1, 0, 0),
        new THREE.Vector3(0, 1, 0),
        new THREE.Vector3(0, -1, 0)
    ];

    for (let direction of directions) {
        light = new THREE.DirectionalLight(0xffffff, 0.2);
        light.position.add(direction);
        shape.add(light);
    }

    light = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.8);
    shape.add(light);

    return shape;
}

export function createCursor() {
    let geometry = new THREE.CylinderGeometry(0.5, 0.5, 0.5, 32);
    let material = new THREE.MeshLambertMaterial({
        color: 0x0000ff,
        transparent: true,
        opacity: 0.5
    });
    let cursor = new THREE.Mesh(geometry, material);
    cursor.rotation.x = Math.PI / 2;
    cursor.visible = false;

    return cursor;
}
