import THREE from 'three';
import * as util from './util';
import Builder from './builder';
import config from './config';
import Wall from './wall';

export default class Roof extends Wall {

    constructor(world, point) {
        super(world, point);
        this.roof = true;
    }

    decorateRoof(point, material) {
        super.decorateWall(point, material);
    }

    isPlaceable(point, time, type, skip, state) {
        if (state && state.point !== point && this.elements[point] && this.elements[point].length > 0) {
            return false;
        }

        if (state && state.wall && state.wall !== this) {
            return false;
        }

        return super.isPlaceable(point, time, type, skip, state);
    }

    close() {
        super.close();

        this.addElement(0, 0.5, 'ridge');
        this.addElement(Math.floor(this.points.length / 2), 0.5, 'ridge');

        this.builder.update();
    }
}
