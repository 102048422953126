import THREE from 'three';
import * as util from './util';
import Builder from './builder';
import config from './config';

export default class Wall {

    constructor(world, point) {
        this.world = world;
        this.floor = world.floor;
        this.points = [];
        this.decoration = [];
        this.elements = [];
        this.closed = false;
        this.model_type = 'walls';
        this.builder = new Builder(this.world, this);
        this.shape = this.builder.shape;

        if (point) {
            this.points = [point.clone(), point.clone()];
            this.builder.update();
        }
    }

    get last() {
        return this.points.length - 1;
    }

    getMeasurePoints(index) {
        let prev = index - 1;
        let next = index + 1;

        if (index === 0) {
            if (this.closed) {
                prev = this.last - 1;
            } else {
                prev = this.last;
            }
        }

        if (index === this.last) {
            next = 0;
        }

        if (!this.closed) {
            return [
                this.points[prev],
                this.points[index]
            ];
        }

        return [
            this.points[prev],
            this.points[index],
            this.points[next]
        ];
    }

    move(point) {
        this.points[this.last] = point.clone();
        this.builder.update();
    }

    add(point) {
        this.move(point);

        // close on double point/double click
        if (this.points[this.last - 1].distanceTo(this.points[this.last]) < config.delta) {
            return this.close();
        }

        // close when adding a point to the start
        if (this.points[0].distanceTo(this.points[this.last]) < config.delta) {
            return this.close();
        }

        this.points.push(point.clone());
        this.builder.update();
    }

    decorateWall(point, material) {
        this.decoration[point] = material;
        this.builder.update();
    }

    setPoint(index, point) {
        this.points[index] = point.clone();
        if (index === 0 && this.closed) {
            this.points[this.last] = point.clone();
        }
        this.builder.update();
    }

    close() {
        this.points[this.last] = this.points[0].clone();

        if (this.points.length > 3) {
            this.closed = true;

            if (!util.is_clockwise(this.points)) {
                this.points = this.points.reverse();
            }
        }

        this.builder.update();
    }

    isPlaceable(point, time, type, skip, state) {
        var length = this.points[point].distanceTo(this.points[point + 1]);
        var index = 0;
        let width = config[type].width;

        if (state && state.width) {
            width = state.width;
        }

        if (this.elements[point]) {
            for (let element of this.elements[point]) {
                let dist = (element.width / 2 + width / 2 + config[element.type].margin) / length;
                if (Math.abs(time - element.time) < dist && index !== skip) {
                    return false;
                }
                index += 1;
            }
            let wall_dist = (width / 2 + config[type].margin) / length;
            if (time < wall_dist || time > 1 - wall_dist) {
                return false;
            }
        }

        return time;
    }

    addElement(point, time, type, state, skip) {
        if (!this.isPlaceable(point, time, type, skip, state)) {
            return false;
        }

        if (!this.elements[point]) {
            this.elements[point] = [];
        }

        let element = {
            wall: this,
            point: point,
            time: time,
            type: type,
            width: config[type].width,
            height: config[type].height,
            z: config[type].z
        };

        this.elements[point].push(element);
        this.elements[point].sort((a, b) => a.time - b.time);
        for (let i = 0; i < this.elements[point].length; i += 1) {
            this.elements[point][i].index = i;
        }

        if (state) {
            element.width = state.width;
            element.height = state.height;
            element.z = state.z;
        }

        this.builder.update();

        return element;
    }

    modifyElement(element, state)
    {
        let previous_width =  element.width;

        element.width = parseInt(state.width, 10) / 100;
        element.height = parseInt(state.height, 10) / 100;
        element.z = parseInt(state.z, 10) / 100;
        element.z = Math.min(element.z, config.wall.height - element.height - 0.1);

        if (!this.isPlaceable(element.point, element.time, element.type, element.index, element)) {
            element.width = previous_width;
        }

        this.builder.update();
    }

    removeElement(point, index) {
        this.elements[point].splice(index, 1);
        for (let i = 0; i < this.elements[point].length; i += 1) {
            this.elements[point][i].index = i;
        }
        this.builder.update();
    }

    finish() {
        this.points.pop();
        this.builder.update();
    }

    remove() {
        this.builder.remove();
    }

    serialize() {
        let item = {
            floor: this.floor,
            closed: this.closed,
            roof: !!this.roof,
            lot: !!this.lot,
            model_type: this.model_type,
            decoration: this.decoration.slice(0),
            points: [],
            elements: []
        };

        for (let point of this.points) {
            item.points.push({x: point.x, y: point.y});
        }

        for (let list of this.elements) {
            if (list) {
                for (let element of list) {
                    if (!item.elements[element.point]) {
                        item.elements[element.point] = [];
                    }
                    item.elements[element.point][element.index] = {
                        point: element.point,
                        type: element.type,
                        time: element.time,
                        width: element.width,
                        height: element.height,
                        z: element.z,
                        index: element.index
                    }
                }
            }
        }

        return item;
    }
}
