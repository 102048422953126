import React from 'react';

const RULER_OFFSET = 15;
const LABEL_OFFSET = 35;

var state = {
    hidden: true,
    start: null,
    end: null
};

export default React.createClass({

    getInitialState() {
        return state;
    },

    componentDidMount() {

        this.props.world.on('measure', (points) => {
            state.hidden = false;

            state.start = points[0];
            state.center = points[1];
            state.end = points[2];
            this._onChange();
        });

        this.props.world.on('cancel', () => {
            state.hidden = true;
            this._onChange();
        });

        this.props.world.on('measure.hide', () => {
            state.hidden = true;
            this._onChange();
        });
    },

    render() {

        if (this.state.hidden) {
            return null;
        }

        let ruler1 = this._createRuler(this.state.start, this.state.center);

        if (!this.state.end) {
            return <div>{ruler1}</div>;
        }

        let ruler2 = this._createRuler(this.state.center, this.state.end);

        return <div>{ruler1} {ruler2}</div>;
    },

    _createRuler(start, end) {
        let screen_start = this.props.world.inverse(start);
        let screen_end = this.props.world.inverse(end);
        let center = screen_start.clone().lerp(screen_end, 0.5);
        let angle = Math.atan2(end.y - start.y, end.x - start.x);
        let distance = screen_start.distanceTo(screen_end);
        let label = start.distanceTo(end).toFixed(2);
        let text_angle = Math.abs(angle) > Math.PI / 2 ? Math.PI - angle : - angle;
        let line_class = 'line' + (start.x === end.x || start.y === end.y ? ' straight' : '');
        return (
            <div>
                <div className="distance" style={{
                'left': center.x + Math.sin(angle) * - LABEL_OFFSET,
                'top': center.y + Math.cos(angle) * - LABEL_OFFSET,
                'transform': 'rotate(' + text_angle + 'rad)'
            }}>{label}</div>
                <div className={line_class} style={{
                'left': center.x + Math.sin(angle) * - RULER_OFFSET,
                'top': center.y + Math.cos(angle) * - RULER_OFFSET,
                'marginLeft': distance / - 2,
                'width': distance,
                'transform': 'rotate(' + -angle + 'rad)'
            }}></div>
            </div>
        );
    },

    _onChange() {
        this.setState(state);
    }
});
