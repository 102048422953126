import THREE from 'three';
import * as util from './util';
import Builder from './builder';
import config from './config';
import Wall from './wall';

export default class Lot extends Wall {

    constructor(world, point) {
        super(world, point);
        this.lot = true;
        this.floor = 0;
    }

    decorateLot(point, material) {
        super.decorateWall(point, material);
    }

    isPlaceable(point, time, type, skip, state) {
        return false;
    }
}
