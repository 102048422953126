import ReactDom from 'react-dom';
import React from 'react';
import Ruler from './editor/components/ruler.react.js';
import Menu from './editor/components/menu.react.js';

import World from './editor/world';

const MENU_WIDTH = 300;

document.addEventListener('DOMContentLoaded', () => {
    var world = new World(0xf6f6f6, window.innerWidth - MENU_WIDTH, window.innerHeight);

    world.container = document.getElementById('canvas');
    world.container.appendChild(world.element);
    ReactDom.render(<Ruler world={world} />, document.getElementById('ruler'));
    ReactDom.render(<Menu world={world} />, document.getElementById('menu'));

    window.addEventListener('resize', () => {
        world.setDimesions(window.innerWidth - MENU_WIDTH, window.innerHeight);
    });

    world.listen();
    world.render();
});
