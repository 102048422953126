
export default {
    delta: 0.5,
    wall: {
        width: 0.25,
        height: 3,
        color: 0xffffff
    },
    gutter: {
        width: 0.25,
        height: 0.1,
        offset: 0,
        color: {
            roof_tiles: 0x605044,
            roof_stone: 0x666666,
            roof_tarch: 0x856741
        }
    },
    window: {
        wall_margin: 1,
        frame_color: 0x666666,
        frame_width: 0.05,
        marker_color: 0xcccccc,
        glass: {
            thickness: 0.02,
            color: 0xccccff,
            opacity: 0.4
        },
        width: 0.8,
        height: 1.5,
        z: 1,
        margin: 0.1
    },
    door: {
        wall_margin: 1,
        frame_color: 0x666666,
        frame_width: 0.05,
        marker_color: 0x555555,
        width: 0.9,
        height: 2.1,
        z: 0,
        margin: 0.1
    },
    ridge: {
        width: 0.5,
        height: 3,
        margin: 0,
        wall_margin: 0
    },
    stone: {
        margin: 0
    },
    wood: {
        margin: 0
    },
    white: {
        margin: 0
    }
};
