import React from 'react';
import ModifyElement from './modify_element.react.js';

var state = {
    mode: 'edit',
    floor: 0,
    select: null,
    tool: null,
    area: 0,
    wall_length: 0
};

export default React.createClass({

    getInitialState() {
        return state;
    },

    componentDidMount() {

        this.props.world.on('select', (tool) => {
            state.tool = tool;
            this._onChange();
        });

        this.props.world.on('cancel', () => {
            state.tool = null;
            state.element = null;
            this._onChange();
        });

        this.props.world.on('floor', (floor) => {
            state.floor = floor;
            this._onChange();
        });

        this.props.world.on('delete', () => {
            state.element = null;
            this._onChange();
        });

        this.props.world.on('edit', () => {
            state.mode = 'edit';
            this._onChange();
        });

        this.props.world.on('view', () => {
            state.mode = 'view';
            this._onChange();
        });

        this.props.world.on('element', (element) => {
            state.element = element;
            this._onChange();
        });

        this.props.world.on('update_measures', (measures) => {
            state.area = measures.area;
            state.wall_length = measures.wall_length;
            this._onChange();
        });
    },

    render() {

        let cancel = (
            <p>
              <button className="btn btn-warning btn-sm" onClick={this._clickCancel}>
                <span className="glyphicon glyphicon-remove" />Annuleren
              </button>
            </p>
        );

        let walls = (
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Muren</h3>
              </div>
              <div className="panel-body">
                  <p>
                    <a href="#"
                        className={this.state.tool === 'wall' ? 'active' : null}
                        onClick={this._clickTool.bind(this, 'wall')}>
                      <span className="glyphicon glyphicon-plus" />plaats buitenmuur
                    </a>
                  </p>
                  <p>
                    <a href="#"
                      className={this.state.tool === 'inner-wall' ? 'active' : null}
                      onClick={this._clickTool.bind(this, 'inner-wall')}>
                        <span className="glyphicon glyphicon-plus" />plaats binnenmuur
                    </a>
                  </p>
                  <p>
                    <a href="#"
                      className={this.state.tool === 'white' ? 'active' : null}
                      onClick={this._clickTool.bind(this, 'white')}>
                        <span className="glyphicon glyphicon-pencil" />witte wandbekleding
                    </a>
                  </p>
                  <p>
                    <a href="#"
                      className={this.state.tool === 'stone' ? 'active' : null}
                      onClick={this._clickTool.bind(this, 'stone')}>
                        <span className="glyphicon glyphicon-pencil" />stenen wandbekleding
                    </a>
                  </p>
                  <p>
                    <a href="#"
                      className={this.state.tool === 'wood' ? 'active' : null}
                      onClick={this._clickTool.bind(this, 'wood')}>
                        <span className="glyphicon glyphicon-pencil" />houten wandbekleding
                    </a>
                  </p>
              </div>
            </div>
        );

        let roof = (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">Dak</h3>
                </div>
                <div className="panel-body">
                    <p>
                        <a href="#"
                           className={this.state.tool === 'roof' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'roof')}>
                            <span className="glyphicon glyphicon-plus" />plaats dak
                        </a>
                    </p>
                    <p>
                        <a href="#"
                           className={this.state.tool === 'roof_tiles' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'roof_tiles')}>
                            <span className="glyphicon glyphicon-pencil" />dakpannen
                        </a>
                    </p>
                    <p>
                        <a href="#"
                           className={this.state.tool === 'roof_stone' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'roof_stone')}>
                            <span className="glyphicon glyphicon-pencil" />stenen dakbedekking
                        </a>
                    </p>
                    <p>
                        <a href="#"
                           className={this.state.tool === 'roof_tarch' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'roof_tarch')}>
                            <span className="glyphicon glyphicon-pencil" />rieten dakbedekking
                        </a>
                    </p>
                </div>
            </div>
        );

        let lot = (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">Perceel en tuin</h3>
                </div>
                <div className="panel-body">
                    <p>
                        <a href="#"
                           className={this.state.tool === 'lot' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'lot')}>
                            <span className="glyphicon glyphicon-plus" />plaats perceel
                        </a>
                    </p>
                    <p>
                        <a href="#"
                           className={this.state.tool === 'car' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'car')}>
                            <span className="glyphicon glyphicon-plus" />plaats auto
                        </a>
                    </p>
                    <p>
                        <a href="#"
                           className={this.state.tool === 'tree' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'tree')}>
                            <span className="glyphicon glyphicon-plus" />plaats boom
                        </a>
                    </p>
                    <p>
                        <a href="#"
                           className={this.state.tool === 'bush' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'bush')}>
                            <span className="glyphicon glyphicon-plus" />plaats struik
                        </a>
                    </p>
                    <p>
                        <a href="#"
                           className={this.state.tool === 'bench' ? 'active' : null}
                           onClick={this._clickTool.bind(this, 'bench')}>
                            <span className="glyphicon glyphicon-plus" />plaats tuinset
                        </a>
                    </p>
                </div>
            </div>
        );

        let elements = (
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Deuren en kozijnen</h3>
              </div>
              <div className="panel-body">
                <p>
                  <a href="#"
                      className={this.state.tool === 'door' ? 'active' : null}
                      onClick={this._clickTool.bind(this, 'door')}>
                    <span className="glyphicon glyphicon-plus" />plaats deur
                  </a>
                </p>
                <p>
                  <a href="#"
                      className={this.state.tool === 'window' ? 'active' : null}
                      onClick={this._clickTool.bind(this, 'window')}>
                    <span className="glyphicon glyphicon-plus" />plaats raam
                  </a>
                </p>
              </div>
            </div>
        );

        return (
            <div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title">Weergave</h3>
                </div>
                <div className="panel-body">
                  <p>
                    <a href="#" className={this.state.mode === 'edit' ? 'active' : null} onClick={this._clickEdit}>
                      <span className="glyphicon glyphicon-pencil" />Bewerken in 2D
                    </a>
                  </p>
                  <p>
                    <a href="#" className={this.state.mode === 'view' ? 'active' : null} onClick={this._clickView}>
                      <span className="glyphicon glyphicon-facetime-video" />Bekijken in 3D
                    </a>
                  </p>
                  <p>
                    <label>Verdieping:</label>
                    <select name="floor" className="form-control" onChange={this._changeFloor} value={this.state.floor}>
                      <option value="0">begane grond</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </p>
                </div>
              </div>
              <div className="panel panel-default actions">
                <div className="panel-heading">
                  <h3 className="panel-title">Acties</h3>
                </div>
                <div className="panel-body">
                  <p>
                    <button className="btn btn-success btn-sm" onClick={this._clickSave}>
                      <span className="glyphicon glyphicon-save" />Opslaan
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={this._clickClear}>
                      <span className="glyphicon glyphicon-trash" />Wis alles
                    </button>
                  </p>
                  <p>
                    <button className="btn btn-default btn-sm" onClick={this._clickUndo}>
                      <span className="glyphicon glyphicon-arrow-left" />Ongedaan
                    </button>
                    <button className="btn btn-default btn-sm" onClick={this._clickRedo}>
                      Herhalen<span className="glyphicon glyphicon-arrow-right" />
                    </button>
                  </p>
                  {this.state.tool ? cancel : null}
                </div>
              </div>
              {this.state.mode === 'edit' && !this.state.element ? walls : null}
              {this.state.mode === 'edit' && !this.state.element ? elements : null}
              {this.state.mode === 'edit' && !this.state.element ? roof : null}
              {this.state.mode === 'edit' && !this.state.element ? lot : null}
              {this.state.element ? <ModifyElement element={this.state.element} world={this.props.world} /> : null}
              <div className="panel panel-default">
                  <div className="panel-heading">
                      <h3 className="panel-title">Detailinformatie casco</h3>
                  </div>
                  <div className="panel-body">
                      <table className="table table-condensed">
                          <tbody>
                              <tr>
                                  <td>Woonoppervlak</td>
                                  <td className="text-right">{this.state.area.toFixed(1)} m<sup>2</sup></td>
                              </tr>
                              <tr>
                                  <td>Strekkende meter muur</td>
                                  <td className="text-right">{this.state.wall_length.toFixed(1)} m</td>
                              </tr>
                              <tr>
                                  <td>Muuroppervlakte</td>
                                  <td className="text-right">{this.state.wall_length.toFixed(1) * 2} m<sup>2</sup></td>
                              </tr>
                              <tr>
                                  <td>Prijsindicatie casco</td>
                                  <td className="text-right">&euro; {Math.round(this.state.area * 800)}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
            </div>
        );
    },

    _clickClear() {
        this.props.world.emit('clear');
    },

    _clickSave() {
        this.props.world.emit('save');
    },

    _clickUndo() {
        this.props.world.emit('undo');
    },

    _clickRedo() {
        this.props.world.emit('redo');
    },

    _clickTool(tool, e) {
        e.preventDefault();
        this.props.world.emit('select', tool);
    },

    _changeFloor(e) {
        this.props.world.emit('floor', e.target.value);
    },

    _clickCancel() {
        this.props.world.emit('cancel');
    },

    _clickEdit(e) {
        e.preventDefault();
        this.props.world.emit('edit');
    },

    _clickView(e) {
        e.preventDefault();
        this.props.world.emit('view');
    },

    _onChange() {
        this.setState(state);
    }
});
