import THREE from 'three';
import config from './config';

import car from '../../images/car.txt';
import bench from '../../images/bench.txt';
import bush from '../../images/bush.txt';
import tree from '../../images/tree.txt';

var loader = new THREE.ObjectLoader();
var modelTypes = {
    car,
    bench,
    bush,
    tree,
};

export default class Object3D {

    constructor(world, type, position) {
        this.world = world;
        this.floor = 0;
        this.type = type;
        this.model_type = 'objects';
        this.setPosition(position);
        loader.load(modelTypes[type], (shape) => {
            this.shape = shape;
            this.setPosition(this.position);
            this.shape.scale.set(0.01, 0.01, 0.01);
            this.world.scene.add(this.shape);
        });
    }

    setPosition (position) {
        if (position.clone) {
            this.position = position.clone();
        } else {
            this.position = new THREE.Vector2(position.x, position.y);
        }
        if (this.shape) {
            this.shape.position.set(this.position.x, this.position.y, this.floor * config.wall.height);
        }
    }

    serialize() {
        return {
            type: this.type,
            floor: this.floor,
            position: this.position.clone(),
            model_type: this.model_type
        }
    }
}
